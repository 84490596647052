import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function HeroIndex2() {
  const data = useStaticQuery(graphql`
    query HeroIndexImageQuery2 {
      file(relativePath: { eq: "collage.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          {/* Needed for proper space */}
          <div className="relative pt-6 px-4 sm:px-6 lg:px-8"></div>

          <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <p className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                Buying or selling your home?
              </p>

              <h1 className="mt-2 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Kristi Adams is your <br className="xl:hidden" />
                local{" "}
                <strong className="text-teal-800">
                  REALTOR
                  <sup>
                    <small>&#174;</small>
                  </sup>
                </strong>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Buying or selling your home is an involved process. It is one of
                the most important decisions you will ever make, personally and
                financially. In any Real Estate venture, you need someone you
                can trust and rely on to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <figure className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Img
          className="h-64 object-cover sm:h-80 md:h-96 lg:w-full lg:h-full"
          fluid={data.file.childImageSharp.fluid}
          alt="Kristi Adams stands in front of photos of satisfied clients."
        />
      </figure>
    </div>
  )
}
