import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
// import { Link } from "gatsby"

export default function TestimonialIndex() {
  const data = useStaticQuery(graphql`
    query TestimonialIndexImageQuery {
      file(relativePath: { eq: "joy_testimonial.jpeg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <div className="py-16 bg-gray-100 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <section>
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <p className="text-sm font-semibold uppercase tracking-wide text-gray-600 sm:text-base lg:text-sm xl:text-base">
              Whether Buying or Selling
            </p>

            <h2 className="mt-2 mb-6 text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              One thing makes a difference
            </h2>
          </div>
          <div className="relative z-10 text-base max-w-prose mx-auto mb-8 lg:max-w-5xl lg:mx-0 lg:pr-72">
            <p className=" text-lg text-gray-600 leading-7">
              The right agent is key to a successful real estate transaction.
              From preparing your home for listing and setting the ideal price.
              To finding you a home that’s the perfect fit. I can simplify the
              entire process.
            </p>
            <p className="mt-4 text-lg text-gray-600 leading-7">
              As your personal Real Estate Agent, I will help guide you through
              the process successfully. My promise is to offer quality service,
              integrity and professionalism in every step of the transaction.
            </p>
          </div>
        </section>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div className="relative z-10 mb-12 lg:mb-0">
            <div className="mb-10 prose text-gray-600 mx-auto lg:max-w-none">
              {/* <section>
                <h2>
                  At London Properties we can offer ‘One Stop Shopping’ for:
                </h2>
                <ul>
                  <li>Mortgage</li>
                  <li>Insurance</li>
                  <li>Marketing</li>
                </ul>
              </section> */}

              <section>
                <h2>I'm here to help</h2>

                <p className="prose-lg">
                  If you or someone you know are in need of Real Estate services
                  of any kind, please feel free to contact me.
                </p>
              </section>
            </div>
            <div className="flex text-base max-w-prose mx-auto lg:max-w-none lg:hidden">
              <div className="rounded-md shadow">
                <Link
                  to="/contact"
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-london-navyblue hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
                >
                  Contact
                  <svg
                    className="-mr-1 ml-3 h-5 w-5 text-gray-200"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </Link>
              </div>
              {/* <div className="rounded-md shadow ml-4">
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out"
                >
                  Learn more
                </a>
              </div> */}
            </div>
          </div>
          <section className="relative text-base max-w-prose mx-auto lg:max-w-none">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
              />
            </svg>
            <h2 className="hidden">Testimonial</h2>
            <blockquote className="relative bg-white rounded-lg shadow-lg">
              <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                {/* <img
                  src="https://tailwindui.com/img/logos/workcation-color.svg"
                  alt="Workcation"
                  className="h-8"
                /> */}
                <div className="relative text-lg text-gray-700 leading-7 font-medium mt-8">
                  <svg
                    className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">
                    Kristi was just phenomenal! Moving from out of the area was
                    such a daunting task. I was just so grateful for her help
                    and guidance. From her grasp of the local real estate
                    market, to her easygoing professional demeanor, she was able
                    to decode what I was really looking for and delivered the
                    perfect home for me. She is even helping me sell my previous
                    home in Newport Beach, Ca. I couldn't be happier with the
                    overall level of service. I would definitely recommend her
                    for anyone looking to relocate from out of the area.
                  </p>
                </div>
              </div>
              <cite className="flex items-center sm:items-start  bg-london-navyblue rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
                <div className="rounded-full border-2 border-white mr-4 sm:-mt-15 sm:mr-6">
                  <Img
                    className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-blue-300"
                    fluid={data.file.childImageSharp.fluid}
                    alt="Joy Thornhill is happy to recommend Kristi Adams."
                  />
                </div>
                <span className="text-gray-300 font-medium leading-6">
                  <strong className="text-white font-bold">
                    Joy Thornhill{" "}
                  </strong>
                  <br className="sm:hidden " />
                  &#8226; new Central Valley homeowner
                </span>
              </cite>
            </blockquote>
          </section>
        </div>
      </div>
    </div>
  )
}
