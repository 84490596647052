import React from "react"
import Layout from "../components/Layout"
// import HeroIndex from "../components/HeroIndex"
import TestimonialIndex from "../components/TestimonialIndex"
import Bottom from "../components/Bottom"
import SEO from "../components/Seo"
import HeroIndex2 from "../components/HeroIndex2"

export default function Home({ location }) {
  return (
    <Layout>
      <SEO title={""} pathname={location.pathname} />
      <HeroIndex2 />
      <TestimonialIndex />
      <Bottom tag="I can help" />
    </Layout>
  )
}
